import { useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCall } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
// import { BsCart3 } from "react-icons/bs";
// import { FiMenu } from "react-icons/fi";

import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import Search from "../components/Search";
import { useEffect } from "react";
import axios from "axios";
import { currencyFormatter } from "../utils/currencyFormatter";
import { BsCart3 } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";

const Navbar = ({ handleMenu }) => {
  const [setSearchItem] = useState("");
  // const [toggle, setToggle] = useState(false);
  const inputField = useRef(null);
  const { items: data } = useSelector((state) => state.category);
  const { cartItems: product } = useSelector((state) => state.cart);
  const { items: logo } = useSelector((state) => state.logo);

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "black" : null,
      Font: isActive ? "bold" : null,
    };
  };
  const Navigate = useNavigate();

  const [searchData, setSearchData] = useState([]);
  const [word, setWord] = useState("");
  const [filterData, setFilterData] = useState([]);

  console.log(word);
  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api-products`);
      return setSearchData(res.data);
    };
    fetchSearchData();
  }, []);

  const handleChange = (e) => {
    const searchWord = e.target.value;
    setWord(() => searchWord);
    const newFilterData = searchData.filter((value) =>
      value.name.toLowerCase().includes(searchWord.toLowerCase())
    );

    if (searchWord === "") {
      setFilterData([]);
    } else {
      setFilterData(newFilterData);
    }
  };

  const handleClose3 = () => {
    setWord();
    setFilterData([]);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    Navigate("/shop");

    //form reset
    setSearchItem("");
    inputField.current.blur();
  };

  // const search = (e) => {
  //   Navigate("/shop");
  //   setSearchItem(e.target.value);
  // };

  // const handleMenu = () => {
  //   setToggle((prev) => !prev);
  // };
  // const handleClose = () => {
  //   setToggle((prev) => !prev);
  // };
  const { userAndToken: userData } = useSelector((state) => state.auth);
  return (
    <>
      <div className="sticky  md:px-0 -top-[70px] md:top-0 md:left-0 shadow-md  md:right-0 z-[9999] bg-white">
  

        <div className={` bg-white hidden md:block text-black py-5`}>
          <div className="container mx-auto flex justify-between">
          <Link
              to={"/"}
              className="brand  h-[3rem]  rounded-md overflow-hidden"
            >
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                className="w-full h-full object-cover"
                alt=""
              />
            </Link>
            <div className="flex justify-center">
              <ul className="flex text-[0.9rem] text-violet-50  lg:text-[1rem] gap-5 py-2 justify-between items-center">
                <li>
                  <NavLink
                    style={navActive}
                    end
                    to={"/"}
                    className="hover:text-gray-300 duration-300"
                  >
                    Home
                  </NavLink>
                </li>
                {data?.slice(0, 6).map((navItem) => (
                  <li key={navItem.id}>
                    <NavLink
                      className="hover:text-gray-300 duration-300"
                      style={navActive}
                      to={`/categorypage/${navItem.id}`}
                      key={navItem.id}
                    >
                      {navItem.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
