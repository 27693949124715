import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";
import { useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import Card from "../components/Card";

const TrendingProduct = () => {
  const { items: data } = useSelector((state) => state.trendingProduct);

  return (
    <div className="wrapper">
      <SectionTitle
        title={"Exciting offers"}
        subtitle={"Enjoy our exclusive offers and promotions"}
      />

      <div className="flex flex-col gap-5">
      {data?.map((product) => (
        <Card key={product.id} product={product} />
      ))}
      </div>

      {data?.length === 0 && (
        <p className="text-center col-span-full">No Products Found</p>
      )}
    </div>
  );
};

export default TrendingProduct;
