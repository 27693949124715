import React from "react";

const SectionTitle = ({ title,subtitle }) => {
  return (
    <div className="my-10 flex flex-col gap-2">
      <h2 className="title text-3xl">{title}</h2>
      <p className="sub-title">{subtitle}</p>
    </div>
  );
};

export default SectionTitle;
